import { Link, graphql } from 'gatsby';
import { createUseStyles } from 'react-jss';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import BookNowButton from '../components/BookNowButton';
import Carousel from '../components/Carousel';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const useStyles = createUseStyles((theme) => ({
  bookNowContainer: {
    textAlign: 'right',
  },
  headerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  information: {
    marginTop: '2rem',
  },
  roomColLeft: {
    flex: '1',
  },
  roomColRight: {
    flex: '1',
    marginLeft: '2rem',
  },
  roomImage: {
  },
  roomInfo: {
    display: 'flex',
  },
  roomRow: {
    borderTop: '1px solid grey',
    marginBottom: '2rem',
    paddingTop: '2rem',
  },
  roomTitle: {
    color: 'black',
    display: 'block',
    fontSize: theme.fontSizeLarge,
    marginBottom: '1rem',
    textAlign: 'center',
  },
  roomTypeHeader: {
    textAlign: 'center',
  },
}));

const RoomGroup = (props) => {
  const classes = useStyles(props);
  const {
    data,
  } = props;

  const {
    allRoomsJson,
    roomGroupsJson,
  } = data;

  const imagesSortted = roomGroupsJson.images.sort((a, b) => {
    const aIndex = roomGroupsJson.imageNames.indexOf(a.fluid.originalName);
    const bIndex = roomGroupsJson.imageNames.indexOf(b.fluid.originalName);
    return aIndex - bIndex;
  });

  const imageComponents = imagesSortted.map((node, i) => (
    <Img alt={roomGroupsJson.imageLabels[i]} fluid={node.fluid} />
  ));

  return (
    <Layout>
      <SEO title={roomGroupsJson.title} />
      <h1 className={classes.headerTitle}>
        {roomGroupsJson.title}
        <BookNowButton propertyType={roomGroupsJson.propertyType} />
      </h1>
      <Carousel
        images={imageComponents}
      />
      <div className={classes.information}>
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: roomGroupsJson.description }} />
        <h2 className={classes.roomTypeHeader}>
          Room Types
        </h2>
        <div>
          {allRoomsJson.nodes.map((room) => {
            const firstImage = room.images.find((image) => (
              image.fluid.originalName === room.imageNames[0]
            ));
            return (
              <div className={classes.roomRow} key={room.title}>
                <Link className={classes.roomTitle} to={room.fields.slug}>
                  {room.title}
                </Link>
                <div className={classes.roomInfo}>
                  <div className={classes.roomColLeft}>
                    <Link alt={room.title} className={classes.roomTitle} to={room.fields.slug}>
                      <Img alt={room.title} className={classes.roomImage} fluid={firstImage.fluid} />
                    </Link>
                  </div>
                  <div className={classes.roomColRight}>
                    {room.description}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={classes.bookNowContainer}>
          <BookNowButton propertyType={roomGroupsJson.propertyType} />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!, $propertyType: String!) {
    roomGroupsJson(fields: { slug: { eq: $slug } }) {
      description
      imageNames
      imageLabels
      images {
        fluid {
          originalName
          ...GatsbyImageSharpFluid
        }
      }
      propertyType
      title
    }
    allRoomsJson(filter: { propertyType: { eq: $propertyType } }) {
      nodes {
        description
        imageNames
        imageLabels
        images {
          fluid {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
        title
        fields {
          slug
        }
      }
    }
  }
`;

RoomGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default RoomGroup;
